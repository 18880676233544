<template>
  <div class="post-code">
    <div :id="`${component.name}Area`" class="area">
      <div class="loading" v-if="!state.loaded">
        <Spinner/>
      </div>
      <!-- iframe here -->
    </div>
  </div>
</template>

<script>
import {defineComponent, onMounted, reactive} from "@vue/composition-api";
import store from "@/scripts/store";
import mixin from "@/scripts/mixin";
import Phone from "@/components/Phone";
import definitions from "@/scripts/definitions";
import Spinner from "@/components/Spinner";

function Component(initialize) {
  this.name = "modalPostCode";
  this.initialize = initialize;
}

export default defineComponent({
  components: {Spinner, Phone},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      state.loaded = false;
      store.commit("tuneModal", {component, size: "md"});
    });

    const state = reactive({
      loaded: false,
    });

    onMounted(() => {
      store.dispatch("appendScript", {
        src: "//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js",
        onEveryLoad() {
          state.loaded = true;

          if (!window.daum) {
            store.commit("closeModal", {name: component.name});
            return store.commit("setSwingMessage", definitions.messages.errorCommon);
          }

          const $area = document.getElementById(`${component.name}Area`);

          new window.daum.Postcode({
            width: "100%",
            height: "100%",
            oncomplete(data) {
              const zonecode = data.zonecode;
              let fullAddress = data.address;

              if (data.addressType === "R") {
                let extraAddress = "";

                if (data.bname !== "") {
                  extraAddress += data.bname;
                }

                if (data.buildingName !== "") {
                  extraAddress += (extraAddress !== "" ? ", " + data.buildingName : data.buildingName);
                }

                fullAddress += extraAddress !== "" ? " (" + extraAddress + ")" : "";
              }

              store.commit("closeModal", {
                name: component.name,
                onClose(modal) {
                  const params = {zonecode, fullAddress};
                  store.dispatch("callback", {modal, params});
                }
              });
            },
            onresize(size) {
              $area.style.height = size.height + "px";
            }
          }).embed($area);
        }
      });
    });

    return {component, state};
  },
});
</script>

<style lang="scss" scoped>
.post-code {
  background: #fff;

  .area {
    height: 444px;
    position: relative;

    .loading {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  @media(max-width: 575px) {
    .area {
      height: 466px;
    }
  }
}
</style>